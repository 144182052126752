import React from "react"
import SVG from "react-inlinesvg"
import { useTranslation } from "react-i18next"

import VisaSVG from "../../../images/icons/visa.svg"
import MastercardSVG from "../../../images/icons/mastercard.svg"
import MirSVG from "../../../images/icons/mir.svg"
import ToneSVG from "../../../images/icons/tone.svg"
import EthSVG from "../../../images/icons/eth.svg"
import BtcSVG from "../../../images/icons/btc.svg"
import TetherSVG from "../../../images/icons/tether.svg"
import AmexLitecoinSVG from "../../../images/icons/amex-litecoin.svg"
import BinanceSVG from "../../../images/icons/binance.svg"
// import RippleSVG from "../../../images/icons/ripple.svg"
// import ApplePaySVG from "../../../images/icons/apple-pay.svg"
// import GooglePaySVG from "../../../images/icons/google-pay.svg"
import useDomainName from "../../../hook/useDomainName"
import { useAppLanguage } from "../../../hook/useAppLanguage"
import SbpSVG from "../../../images/icons/sbp.svg"

const FooterAcceptPayment = () => {
  const { t } = useTranslation()
  const { isMainSite, isUSASite } = useDomainName()

  if (isUSASite) return <></>

  return (
    <div className="accept-payment">
      {t("We accept for payment:")}
      <br />
      {isMainSite && (
        <>
          <DefaultAcceptPayment />
          <CryptoAcceptPayment />
        </>
      )}
    </div>
  )
}

const DefaultAcceptPayment = () => {
  const locale = useAppLanguage()

  return (
    <>
      <SVG src={VisaSVG} style={{ filter: "brightness(170%)" }} />
      <SVG src={MastercardSVG} />
      {/*<SVG src={ApplePaySVG} />*/}
      {/*<SVG src={GooglePaySVG} />*/}
      {locale === "ru" && <SVG src={MirSVG} />}
      {locale === "ru" && <SVG src={SbpSVG} />}
    </>
  )
}

const CryptoAcceptPayment = () => {
  return (
    <>
      <SVG src={ToneSVG} style={{ marginLeft: -5 }} />
      <SVG src={EthSVG} style={{ marginLeft: -5 }} />
      <SVG src={BtcSVG} style={{ marginLeft: -5 }} />
      <SVG src={TetherSVG} style={{ marginLeft: -5 }} />
      <SVG src={AmexLitecoinSVG} style={{ marginLeft: -5 }} />
      <SVG src={BinanceSVG} style={{ marginLeft: -5 }} />
      {/* <SVG src={RippleSVG} style={{ marginLeft: -5 }} /> */}
    </>
  )
}

export default FooterAcceptPayment
