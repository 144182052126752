// extracted by mini-css-extract-plugin
export var bottom = "Logo-module--bottom--935df";
export var companyName = "Logo-module--companyName--1f78b";
export var container = "Logo-module--container--dea1f";
export var cube = "Logo-module--cube--886f6";
export var left = "Logo-module--left--86c9d";
export var lightBackground = "Logo-module--lightBackground--66036";
export var logoAnimateCube = "Logo-module--logo-animate-cube--de50a";
export var notTransparent = "Logo-module--notTransparent--3f970";
export var right = "Logo-module--right--2fa2e";
export var top = "Logo-module--top--771e6";
export var wrapper = "Logo-module--wrapper--501b2";