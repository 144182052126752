import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import cx from "classnames"

import { getPhoneNumber } from "../../../helpers/config"
import useDomainName from "../../../hook/useDomainName"

const MenuContacts = ({ isFooter = true, className }) => {
  const { t } = useTranslation()
  const { isUSASite, isMainSite } = useDomainName()

  const domainWithCorrection = useMemo(() => isMainSite, [])

  return (
    <ul
      className={cx(
        "menu",
        isFooter ? "menu_footer" : "",
        className,
        domainWithCorrection ? "top-correction" : "",
      )}
    >
      <li className="menu__item">{t("Contact Us")}</li>
      <li className="menu__item">
        <a href={`mailto:${process.env.GATSBY_CONTACT_EMAIL}`}>
          {process.env.GATSBY_CONTACT_EMAIL}
        </a>
      </li>
      <ContactsDefault isUSASites={isUSASite} />
      {!isUSASite && (
        <li className="menu__item menu__item_branch">
          <br />
          {t("Branch in USA")}: <a href="https://usava.team">USAva.Team</a>
        </li>
      )}
      {/*{i18n.language === 'ru' ? (*/}
      {/*  <li className="menu__item">*/}
      {/*    <a href={t('{phone_number_link}')}>{t('{phone_number}')}</a>*/}
      {/*  </li>*/}
      {/*) : (*/}
      {/*  ''*/}
      {/*)}*/}
      {/*<li className="menu__item">{t('{address}')}</li>*/}
    </ul>
  )
}

const ContactsDefault = ({ isUSASites }) => {
  const { i18n } = useTranslation()

  return (
    <>
      {isUSASites && (
        <li className="menu__item">
          <a data-telegram="usava_team" href="tel:+18475046041">
            +1 847 504 6041
          </a>
        </li>
      )}
      {["en", "ru"].includes(i18n.language) && (
        <li className="menu__item">
          <a
            data-telegram={!isUSASites ? "sava_team" : ""}
            href={`tel:${getPhoneNumber()}`}
          >
            {getPhoneNumber(true)}
          </a>
        </li>
      )}
      {i18n.language === "ru" && (
        /* TODO: Заменить статику на getPhoneNumber */
        <>
          <li className="menu__item">
            <a href="tel:+79850383639">+7 (985) 038-36-39</a>
          </li>
        </>
      )}
    </>
  )
}

export default MenuContacts
