import React from "react"
import DefaultLogo from "./Default"
import NewYearLogo from "./NewYear"

const LOGO_COMPONENTS = {
  default: DefaultLogo,
  newYear: NewYearLogo,
}

const Logo = props => {
  const logoStyle = process.env.GATSBY_LOGO_STYLE || "default"

  const SelectedLogo = LOGO_COMPONENTS[logoStyle] || DefaultLogo

  return <SelectedLogo {...props} />
}

export default Logo
