import React, { Component } from "react"
import { withTranslation } from "react-i18next"
import SVG from "react-inlinesvg"

import Menu from "../Menu/Menu"
// import ThemeToggler from "../ThemeToggler"
import LangSwitcher from "../langSwitcher"
import Logo from "../UI/Logo/index.jsx"

import "./PhoneMenu.scss"
import burger from "../../images/burger_menu.svg"
import close from "../../images/close.svg"
import cx from "classnames"
import MenuContacts from "../Menu/components/menuContacts"
// import bigLogo from "../../images/big_logo.svg"

class PhoneMenu extends Component {
  // changeLanguage = lng => {
  //   this.props.i18n.changeLanguage(lng)
  // }
  closeMenu = () => {
    this.setState({ phoneMenuActive: false })
  }

  constructor(props) {
    super(props)
    this.state = { phoneMenuActive: false }
  }

  render() {
    const { style, className } = this.props

    return (
      <>
        <span
          style={style}
          className={cx(
            "burger",
            this.state.phoneMenuActive ? "stateClose" : "",
            "phoneMenu__burger",
            className,
          )}
          onClick={() =>
            this.setState({ phoneMenuActive: !this.state.phoneMenuActive })
          }
        >
          {!this.state.phoneMenuActive ? (
            <>
              <SVG src={burger} />
            </>
          ) : (
            <>
              <SVG src={close} />
            </>
          )}
        </span>
        <div
          className={[
            "phoneMenu",
            !this.state.phoneMenuActive ? "hidden" : "",
          ].join(" ")}
        >
          <div
            className={[
              "phoneMenu__main",
              this.state.phoneMenuActive ? "opened" : "",
            ].join(" ")}
          >
            <div className="phoneMenu__bigLogo">
              {/* <SVG src={bigLogo} /> */}
              <Logo size={150} withBackground />
            </div>

            <div className="menu menu_phoneMenu">
              <div className="phoneMenu__langSwitcher">
                <LangSwitcher />
              </div>
              <Menu onClose={this.closeMenu} />
              {/*<div className="menu__item">*/}
              {/*  <Link to="/moving-from-magento-1-to-magento-2-upgrade-service">*/}
              {/*    Magento 2.3.x*/}
              {/*  </Link>*/}
              {/*</div>*/}
              <MenuContacts
                isFooter={false}
                className="header-menu__contacts"
              />
            </div>
            {/* <div className="header__top-interface"> */}

            {/* <div className="language">
              <span
                className={
                  "language__select ru" +
                  (this.props.i18n.language === "ru"
                    ? " language__select_active"
                    : "")
                }
                onClick={() => this.changeLanguage("ru")}
              >
                RU
              </span>
              <span
                className={
                  "language__select eng" +
                  (this.props.i18n.language === "en"
                    ? " language__select_active"
                    : "")
                }
                onClick={() => this.changeLanguage("en")}
              >
                EN
              </span>

               <span className="language__separator"> / </span> //comment
            </div> */}
            {/* <ThemeToggler /> */}

            {/* </div> */}
          </div>
        </div>
      </>
    )
  }
}

export default withTranslation()(PhoneMenu)
