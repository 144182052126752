// extracted by mini-css-extract-plugin
export var bottom = "Logo-module--bottom--a1a9c";
export var companyName = "Logo-module--companyName--3f903";
export var container = "Logo-module--container--719ad";
export var cube = "Logo-module--cube--dd10d";
export var left = "Logo-module--left--f4ab1";
export var lightBackground = "Logo-module--lightBackground--eedf3";
export var logoAnimateCube = "Logo-module--logo-animate-cube--7a7c1";
export var notTransparent = "Logo-module--notTransparent--84c7e";
export var right = "Logo-module--right--854e2";
export var top = "Logo-module--top--8d5f2";
export var wrapper = "Logo-module--wrapper--e1a33";