import React, { useState, useEffect } from "react"
import "normalize.css"

import Preloader from "./Preloader"
import Footer from "./Footer/Footer"
import HeaderTop from "./HeaderTop"
import PageLoader from "./PageLoader"
import "../styles/common.scss"
import useDomainName from "../hook/useDomainName"
import { LoadContextProvider } from "../context/index"
import Cookies from "./Cookies"
import { hasWindow } from "../helpers/config"
import WhatsappFloatingButton from "./WhatsappFloatingButton"
import { useLocation } from "@reach/router"

const Layout = ({ children }) => {
  const [isLoaded, setLoaded] = useState(false)
  const [progress, setProgress] = useState(0)
  const { isUSASite } = useDomainName()
  const location = useLocation()

  useEffect(() => {
    setLoaded(false)
    setProgress(0)

    const simulateProgress = () => {
      let progressValue = 0
      const interval = setInterval(() => {
        if (progressValue < 100) {
          progressValue += 10
          setProgress(progressValue)
        } else {
          clearInterval(interval)
          setLoaded(true)
        }
      }, 100)

      return () => clearInterval(interval)
    }

    simulateProgress()

    const timer = setTimeout(() => {
      setLoaded(true)
    }, 1400)

    let jivoSite = document.querySelector("#jivo-iframe-container")
    if (!jivoSite) {
      let script = document.createElement("script"),
        scripts = document.getElementsByTagName("script")[0]
      script.src = `//code.jivo.ru/widget/${isUSASite ? "iCC9vkAj5g" : "ZPgcWkpc93"}`
      script.async = "true"
      if (scripts) {
        scripts.parentNode.insertBefore(script, scripts)
      }
    }

    return () => clearTimeout(timer)
  }, [isUSASite, location])

  useEffect(() => {
    const loadFunc = () => setLoaded(true)
    if (hasWindow() && !isLoaded) {
      window.addEventListener("load", loadFunc)
      const loadingShade = document.getElementById("loading-shade")
      if (loadingShade) {
        loadingShade.remove()
      }
    }
    return () => {
      if (hasWindow()) {
        window.removeEventListener("load", loadFunc)
      }
    }
  }, [isLoaded])

  return (
    <LoadContextProvider value={isLoaded}>
      <PageLoader loading={!isLoaded} />
      {!isLoaded && <Preloader progress={progress} />}
      {children}
      <Cookies />
      <PageLoader />
      <WhatsappFloatingButton />
    </LoadContextProvider>
  )
}

export default Layout
